import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Link, navigate} from 'gatsby';
import withRoot from '../withRoot';
import Cover from './cover';
import Grid from '@material-ui/core/Grid/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
	root: {
		textAlign: 'left',
		width: '100%',
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
	grid: {
		width: '100%',
		maxWidth: 1440,
		margin: 'auto',
	},
	btn: {
		color: theme.palette.primary.dark,
		textDecoration: 'none',
	},
	headline: {
		marginTop: theme.spacing.unit,
	},
});

class ReleasesGrid extends React.Component {

	renderRelease (i) {
		const {classes, big} = this.props;

		return (
			<Grid item xs={big ? 12 : 6} sm={big ? 6 : 3} md={big ? 3 : 2} onClick={() => navigate(i.fields.slug)}
			      style={{cursor: 'pointer'}}>
				{i.cover ? <Cover imgName={i.cover} release={i}/> :
					<Cover release={i}/>}
				<Typography variant={'h5'} className={classes.headline}>{i.name}</Typography>
				<Typography
					variant={'subtitle1'}>{i.subName || i.type.replace(/^./, i.type[ 0 ].toUpperCase())}</Typography>
			</Grid>
		);
	}

	render () {
		const {classes, releases, name, needButton} = this.props;

		return (
			<div className={classes.root}>
				<Grid container spacing={3} justify={'space-around'} className={classes.grid}>
					<Grid item container xs={12} spacing={3} justify={'center'}>
						<Grid item xs={12} sm={needButton ? 8 : 12} md={needButton ? 7 : 10}>
							<Typography variant={'h1'}>{name}</Typography>
						</Grid>
						{needButton ? <Hidden xsDown><Grid item container sm={4} md={3} justify={'flex-end'}>
							<Link to={'/releases'} className={classes.btn}><Button color={'inherit'}>All
								releases</Button></Link>
						</Grid></Hidden> : null}
					</Grid>
					<Grid item container xs={12} md={10} spacing={3} justify={'left'}>
						{releases.map((i) => this.renderRelease(i.node))}
					</Grid>
					{needButton ? <Hidden smUp><Grid item container sm={4} md={3} justify={'center'}>
						<Link to={'/releases'} className={classes.btn}><Button color={'inherit'}>All
							releases</Button></Link>
					</Grid></Hidden> : null}
				</Grid>

			</div>
		);
	}
};

ReleasesGrid.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(ReleasesGrid));
